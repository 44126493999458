<template>
  <nav
    class="navigation tw-fixed tw-h-full tw-z-20 tw-bg-gray-2 lg:tw-block lg:tw-translate-x-0 tw-text-gray-white"
    :inert="navigationInert"
    :class="[
      navigationInert ? 'tw-pointer-events-none' : 'tw-pointer-events-auto',
      expanded ? 'tw-block navigation--expanded' : 'tw-hidden',
    ]"
  >
    <!--Mobile nav backdrop-->
    <div
      v-if="expanded"
      class="tw-fixed lg:tw-hidden tw-h-full tw-w-screen tw-bg-opacity-black-9 -tw-z-10"
      @click="collapseNavigation"
    />
    <!--Overlay section types backdrop-->
    <div
      v-if="showOverlaySectionBackdrop"
      class="tw-absolute tw-h-full tw-w-full tw-bg-opacity-black-9 tw-z-10"
      @click="resetInflatedCollapseMenu"
    />
    <div class="tw-h-full tw-flex tw-flex-col tw-color-gray-white tw-px-2">
      <!--
			Adding some horizontal padding to parent to give scrollbar som distance to nav edge.
			Adding horizontal padding to children to avoid issues with outline on tab-focus in the section with overflow-scroll.
			-->
      <!-- LOGO -->
      <div
        class="tw-flex tw-items-center tw-border-b tw-border-opacity-white-4 tw-h-20 tw-shrink-0 tw-px-1"
        :class="[expanded ? 'tw-justify-between ' : 'tw-justify-center']"
      >
        <router-link
          :to="{ name: RouteNames.DASHBOARD }"
          class="tw-py-4 tw-px-2.5 rounded focus:tw-outline-2 focus:tw-outline-gray-white"
          :class="{ 'tw-hidden': !expanded }"
        >
          <LogoLoomis />
        </router-link>
        <NavigationToggle />
      </div>
      <!-- Merchant section only enabled for mobile so far (until single merchant is implemented on more pages) -->
      <!-- MERCHANT -->
      <div class="tw-border-b tw-border-opacity-white-4 tw-px-1 tw-block lg:tw-hidden">
        <MerchantSection
          class="tw-my-4"
          :text="selectedMerchant?.attributes.name || ''"
          :is-inflated="currentlyInflatedCollapseMenu === 'merchant'"
          :active="getMeta('section') === 'merchant'"
          @click="onSectionClick('merchant')"
          @close="resetInflatedCollapseMenu"
        />
      </div>
      <!-- LINK SECTIONS -->
      <menu class="tw-my-2 tw-px-1 tw-overflow-y-auto">
        <li
          v-for="(linkSection, index) in linkSections"
          :key="index"
        >
          <MenuSection
            v-if="displayLinkSection(linkSection)"
            class="tw-my-2"
            :links="linkSection.links?.filter(displayLink)"
            :text="linkSection.text"
            :to="linkSection.to"
            :active="getMeta('section') === linkSection.section && !linkSection.to"
            :icon="linkSection.icon"
            :is-inflated="currentlyInflatedCollapseMenu === linkSection.section"
            @click="onSectionClick(linkSection.section)"
            @close="resetInflatedCollapseMenu"
          />
        </li>
      </menu>
      <div class="tw-w-full tw-mt-auto tw-px-1 tw-z-20">
        <menu>
          <!-- ADMIN -->
          <li v-if="adminLinkSection.links?.some(displayLink)">
            <MenuSection
              menu-type="overlay"
              class="tw-my-2"
              :text="adminLinkSection.text"
              :icon="adminLinkSection.icon"
              :links="adminLinkSection.links.filter(displayLink)"
              :active="adminLinkSection.active"
              :is-inflated="currentlyInflatedOverlayMenu === Section.ADMIN"
              bottom-align-dropdown
              @click="onOverlaySectionClick(Section.ADMIN)"
              @close="resetInflatedOverlayMenu"
            />
          </li>
          <!-- ACCOUNT SETTINGS -->
          <li>
            <MenuSection
              class="tw-mb-4"
              menu-type="overlay"
              :text="accountSettingsSection.text"
              :icon="accountSettingsSection.icon"
              :links="accountSettingsSection.links"
              :active="accountSettingsSection.active"
              :is-inflated="currentlyInflatedOverlayMenu === Section.ACCOUNT_SETTINGS"
              bottom-align-dropdown
              @click="onOverlaySectionClick(Section.ACCOUNT_SETTINGS)"
              @close="resetInflatedOverlayMenu"
            />
          </li>
        </menu>
      </div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import type { LuiIconName } from '@loomispay/loomis-ui'
import { storeToRefs } from 'pinia'
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { type RouteLocationRaw, useRoute } from 'vue-router'

import type { LinkSectionLink } from '@/interfaces/Frontend/Navigation'

import LogoLoomis from '@/components/Layout/LogoLoomis.vue'
import NavigationToggle from '@/components/Layout/Navigation/NavigationToggle.vue'
import { usePermissions } from '@/composables/usePermissions'
import modal from '@/modal'
import { Section, type SectionTypeValue } from '@/router/shared/constants'
import { PortalPermissionNames, type PortalPermissionValue } from '@/shared/constants/permissions'
import { RouteNames } from '@/shared/routes'
import { useAuthStore } from '@/stores/auth'
import { useMerchantsStore } from '@/stores/merchants'
import { useNavigationStore } from '@/stores/navigation'
import { useTailwindStore } from '@/stores/tailwind'

import MenuSection from './MenuSection.vue'
import MerchantSection from './MerchantSection.vue'

// import MerchantSection from './MerchantSection.vue'

interface LinkSection {
  section: SectionTypeValue
  text: string
  icon?: LuiIconName
  links?: LinkSectionLink[]
  to?: RouteLocationRaw
  active?: boolean
  permission?: PortalPermissionValue[]
}

const route = useRoute()
const { t } = useI18n()
const { hasPermission } = usePermissions()
const { selectedMerchant, anyMerchantHasAccessToFeature, merchantHasDailyVatNetSettling } = {
  ...useMerchantsStore(),
  ...storeToRefs(useMerchantsStore()),
}
const { expanded, manuallyCollapsed, navigationInert } = storeToRefs(useNavigationStore())
const { collapseNavigation, expandNavigation } = useNavigationStore()
const { screens } = useTailwindStore()
const { originalToken, name: fullName } = storeToRefs(useAuthStore())
const { logOut, stopImpersonation } = useAuthStore()

const getMeta = (key: string) => route.matched.map((m) => m.meta[key]).find((s) => s) as string

const currentlyInflatedCollapseMenu = ref<string>(expanded.value ? getMeta('section') : '')

const currentlyInflatedOverlayMenu = ref<string>('')

const lastWidth = ref(0)

const showOverlaySectionBackdrop = computed(() => {
  return expanded.value && currentlyInflatedOverlayMenu.value
})

const linkSections = computed<LinkSection[]>(() => [
  {
    text: t('pages.dashboard'),
    section: Section.DASHBOARD,
    active: getMeta('section') === Section.DASHBOARD,
    icon: 'dashboard',
    to: { name: RouteNames.DASHBOARD },
    permission: ['Admin'],
  },
  {
    text: t('pages.pointOfSale'),
    section: Section.ADMINISTRATION,
    active: getMeta('section') === 'administration',
    icon: 'point-of-sale',
    links: [
      {
        to: { name: RouteNames.ADMINISTRATION_STORES_LIST },
        text: t('pages.pointOfSale.locations'),
        permission: [PortalPermissionNames.SHOW_STORES],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_TERMINALS_LIST },
        text: t('pages.pointOfSale.terminals'),
        permission: [PortalPermissionNames.SHOW_TERMINALS],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_WAITERS_LIST },
        text: t('pages.pointOfSale.staff'),
        permission: [PortalPermissionNames.SHOW_WAITERS],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_RECEIPTS_LIST },
        text: t('pages.pointOfSale.receipts'),
        permission: [PortalPermissionNames.SHOW_RECEIPTS],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_IMAGES_LIST },
        text: t('pages.pointOfSale.images'),
        permission: [PortalPermissionNames.SHOW_IMAGES],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_CASH_BOXES_LIST },
        text: t('pages.pointOfSale.cashBoxes'),
        permission: [PortalPermissionNames.SHOW_CASH_BOXES],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_DISCOUNT_BUTTONS_LIST },
        text: t('pages.pointOfSale.discountButtons'),
        permission: [PortalPermissionNames.SHOW_DISCOUNT_BUTTONS],
      },
      {
        to: { name: RouteNames.ADMINISTRATION_CONNECTIONS_LIST },
        text: t('pages.pointOfSale.connections'),
        permission: [PortalPermissionNames.SHOW_CONNECTIONS],
      },
      {
        to: { name: RouteNames.RESERVATIONS_STORE_LIST },
        text: t('pages.pointOfSale.reservations'),
        permission: [PortalPermissionNames.SHOW_STORE_MAPPINGS],
        feature: 'reservations',
      },
      {
        to: { name: RouteNames.TABLES_RESTAURANT_LIST },
        text: t('pages.pointOfSale.tables'),
        permission: [PortalPermissionNames.SHOW_TABLES],
        // feature: 'tables',
      },
    ],
  },
  {
    text: t('pages.products'),
    section: Section.PRODUCTS,
    active: getMeta('section') === Section.PRODUCTS,
    icon: 'products',
    links: [
      {
        to: { name: RouteNames.PRODUCTS_PRODUCTS_LIST },
        text: t('pages.products'),
        permission: [PortalPermissionNames.SHOW_PRODUCTS],
      },
      {
        to: { name: RouteNames.PRODUCTS_PRODUCT_FOLDERS_LIST },
        text: t('pages.products.productFolders'),
        permission: [PortalPermissionNames.SHOW_PRODUCT_FOLDERS],
      },
      {
        to: { name: RouteNames.PRODUCTS_PRODUCT_GROUPS_LIST },
        text: t('pages.products.productGroups'),
        permission: [PortalPermissionNames.SHOW_PRODUCT_GROUPS],
      },
      {
        to: { name: RouteNames.PRODUCTS_MODIFIERS_LIST },
        text: t('pages.products.modifiers'),
        permission: [PortalPermissionNames.SHOW_MODIFIERS],
      },
      {
        to: { name: RouteNames.PRODUCTS_MODIFIER_GROUPS_LIST },
        text: t('pages.products.modifierGroups'),
        permission: [PortalPermissionNames.SHOW_MODIFIER_GROUPS],
      },
      {
        to: { name: RouteNames.PRODUCTS_BUNDLES_LIST },
        text: t('pages.products.bundles'),
        permission: [PortalPermissionNames.SHOW_BUNDLES],
      },
      {
        to: { name: RouteNames.PRODUCTS_PRICE_GROUPS_LIST },
        text: t('pages.products.priceGroups'),
        permission: [PortalPermissionNames.SHOW_PRICE_GROUPS],
      },
    ],
  },
  // Finances
  {
    text: t('pages.finances'),
    section: Section.FINANCES,
    active: getMeta('section') === Section.FINANCES,
    icon: 'finance',
    links: [
      {
        to: hasPermission('show-orders')
          ? { name: RouteNames.FINANCES_SALES_ORDERS_LIST }
          : { name: RouteNames.FINANCES_SALES_TRANSACTIONS_LIST },
        text: t('pages.finances.sales'),
        permission: [PortalPermissionNames.SHOW_TRANSACTIONS, PortalPermissionNames.SHOW_ORDERS],
      },
      {
        to: { name: RouteNames.FINANCES_RETURN_ORDERS_LIST },
        text: t('pages.finances.returnOrders'),
        permission: [PortalPermissionNames.SHOW_RETURN_ORDERS],
      },
      {
        to: { name: RouteNames.FINANCES_TRADING_DAYS_LIST },
        text: t('pages.finances.tradingDays'),
        permission: [PortalPermissionNames.SHOW_TRADING_DAYS],
      },
      {
        to: { name: RouteNames.CASH_DEPOSITS },
        text: t('pages.finances.cashDeposits'),
        permission: [PortalPermissionNames.SHOW_CASH_CAPABILITIES],
      },
      {
        to: { name: RouteNames.FINANCES_CREDIT_VOUCHERS_LIST },
        text: t('pages.finances.creditVouchers'),
        permission: [PortalPermissionNames.SHOW_CREDIT_VOUCHERS],
      },
      {
        to: { name: RouteNames.FINANCES_EXTERNAL_ORDER_PROVIDERS_LIST },
        text: t('pages.finances.externalOrderProviders'),
        permission: [PortalPermissionNames.SHOW_EXTERNAL_ORDER_PROVIDERS],
      },
      {
        to: { name: RouteNames.FINANCES_FINANCE_ACCOUNTS_LIST },
        text: t('pages.finances.financeAccounts'),
        permission: [PortalPermissionNames.SHOW_FINANCE_ACCOUNTS],
      },
      {
        to: { name: RouteNames.FINANCES_PAYMENT_METHOD_ACCOUNTS_LIST },
        text: t('pages.finances.paymentMethodAccounts'),
        permission: [PortalPermissionNames.SHOW_PAYMENT_METHOD_ACCOUNTS],
      },
      {
        to: { name: RouteNames.FINANCES_DOCUMENTS },
        text: t(merchantHasDailyVatNetSettling.value ? 'pages.finances.documents' : 'pages.finances.invoices'),
        permission: [PortalPermissionNames.SHOW_MERCHANT_INVOICES],
      },
      {
        to: { name: RouteNames.FINANCES_PAYOUTS },
        text: t('pages.payouts'),
        permission: [PortalPermissionNames.SHOW_PAYOUT_SUMMARIES],
      },
    ],
  },
  // Reports
  {
    text: t('pages.reports'),
    section: Section.REPORTS,
    active: getMeta('section') === Section.REPORTS,
    icon: 'report',
    links: [
      {
        to: { name: RouteNames.REPORTS_PRODUCTS },
        text: t('pages.reports.products'),
        permission: [PortalPermissionNames.SHOW_PRODUCTS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_PRODUCT_GROUPS },
        text: t('pages.reports.productGroups'),
        permission: [PortalPermissionNames.SHOW_PRODUCT_GROUPS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_RETURN_ITEMS },
        text: t('pages.reports.returnItems'),
        permission: [PortalPermissionNames.SHOW_RETURN_ITEMS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_WAITERS },
        text: t('pages.reports.staff'),
        permission: [PortalPermissionNames.SHOW_WAITERS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_WEIGHT },
        text: t('pages.reports.weight'),
        permission: [PortalPermissionNames.SHOW_WEIGHT_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_BUNDLES },
        text: t('pages.reports.bundles'),
        permission: [PortalPermissionNames.SHOW_BUNDLES_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_MODIFIERS },
        text: t('pages.reports.modifiers'),
        permission: [PortalPermissionNames.SHOW_MODIFIERS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_FINANCE_ACCOUNTS },
        text: t('pages.reports.financeAccounts'),
        permission: [PortalPermissionNames.SHOW_FINANCE_ACCOUNTS_REPORT],
      },
      {
        to: { name: RouteNames.REPORTS_FINANCIAL_REPORT },
        text: t('pages.reports.financialReport'),
        permission: [PortalPermissionNames.CREATE_STORED_FINANCIAL_REPORT_REPORTS],
      },
      {
        to: { name: RouteNames.REPORTS_GENERATED_REPORTS },
        text: t('pages.reports.generatedReports'),
      },
      {
        to: { name: RouteNames.REPORTS_STORED_REPORTS_LIST },
        text: t('pages.reports.storedReports'),
        permission: [PortalPermissionNames.SHOW_STORED_REPORTS],
      },
    ],
  },
  {
    text: t('pages.salesChannels'),
    section: Section.SALES_CHANNELS,
    active: getMeta('section') === Section.SALES_CHANNELS,
    icon: 'sales-channels',
    permission: [PortalPermissionNames.SHOW_STORE_MAPPINGS],
    links: [
      {
        to: { name: RouteNames.DELIVERY_STORE_MAPPINGS_LIST },
        text: t('pages.delivery'),
        permission: [PortalPermissionNames.SHOW_STORE_MAPPINGS],
      },
    ],
  },
  {
    text: t('global.integrations'),
    section: Section.INTEGRATIONS,
    icon: 'apps',
    active: getMeta('section') === Section.INTEGRATIONS,
    to: { name: RouteNames.APPLICATIONS_LIST_APPLICATIONS },
  },
])

const adminLinkSection = computed<LinkSection>(() => ({
  text: t('pages.admin'),
  section: Section.ADMIN,
  active: getMeta('section') === Section.ADMIN,
  icon: 'admin',
  links: [
    {
      to: { name: RouteNames.PACKAGES_LIST },
      text: t('pages.system.packages'),
      permission: [PortalPermissionNames.SHOW_PACKAGES],
    },
    {
      to: { name: RouteNames.USERS_LIST },
      text: t('pages.accountSettings.users'),
      permission: [PortalPermissionNames.SHOW_USERS],
    },
    {
      to: { name: RouteNames.MERCHANTS_LIST },
      text: t('pages.system.merchants'),
      permission: [PortalPermissionNames.SHOW_MERCHANTS],
    },
    {
      to: { name: RouteNames.SYSTEM_CLIENTS_LIST },
      text: t('pages.system.clients'),
      permission: [PortalPermissionNames.SHOW_CLIENTS],
    },
    {
      to: { name: RouteNames.SYSTEM_NOTICES_LIST },
      text: t('pages.system.notices'),
      permission: [PortalPermissionNames.SHOW_NOTICES],
    },
    {
      to: { name: RouteNames.SYSTEM_ROLES_LIST },
      text: t('pages.system.roles'),
      permission: [PortalPermissionNames.SHOW_ROLES],
    },
    {
      to: { name: RouteNames.SYSTEM_ONBOARDING_PROFILES_LIST },
      text: t('pages.system.onboardingProfiles'),
      permission: [PortalPermissionNames.SHOW_ONBOARDING_PROFILES],
    },
    {
      to: { name: RouteNames.SYSTEM_TERMINAL_TYPES_LIST },
      text: t('pages.system.terminalTypes'),
      permission: [PortalPermissionNames.SHOW_TERMINAL_TYPES],
    },
    {
      to: { name: RouteNames.SYSTEM_TERMINAL_EVENTS },
      text: t('pages.system.terminalEvents'),
      permission: [PortalPermissionNames.SHOW_TERMINAL_EVENTS],
    },
    {
      to: { name: RouteNames.SYSTEM_SKATTEVERKET_REGISTERED_TERMINALS },
      text: t('pages.system.registeredTerminals'),
      permission: [PortalPermissionNames.SHOW_SKATTEVERKET_REGISTERED_TERMINALS],
    },
    {
      to: { name: RouteNames.SYSTEM_LOG_LIST },
      text: t('pages.system.log'),
      permission: [PortalPermissionNames.SHOW_LOG_LINES],
    },
  ],
}))

const accountSettingsSection = computed<LinkSection>(() => {
  const links: LinkSectionLink[] = [
    {
      text: t('pages.account.settings'),
      to: { name: RouteNames.SETTINGS },
    },
    {
      text: t('pages.account.cookieSettings'),
      click: () =>
        // OneTrust might not be available, so declaring it as optional so we don't get Bugsnag errors
        typeof window.OneTrust?.ToggleInfoDisplay === 'function'
          ? window.OneTrust.ToggleInfoDisplay()
          : modal.alert(t('settings.alert.cookiePanelNotFound')),
    },
    {
      class: '!tw-text-red-9',
      text: t('pages.account.logOut'),
      click: () => logOut(true),
    },
  ]

  if (originalToken.value) {
    links.unshift({
      text: 'Stop impersonation', // Don't localise this, it's only for admins
      click: stopImpersonation,
    })
  }

  return {
    text: fullName.value,
    section: Section.ACCOUNT_SETTINGS,
    active: getMeta('section') === Section.ACCOUNT_SETTINGS,
    icon: 'user',
    links,
  }
})

const displayLink = (link: LinkSectionLink) => {
  if (!link.permission) {
    return true
  }
  if (!link.permission.some((permission) => hasPermission(permission))) {
    return false
  }
  if (!link.feature) {
    return true
  }
  return anyMerchantHasAccessToFeature(link.feature)
}

const displayLinkSection = (section: LinkSection) => {
  if (section.permission) {
    return section.permission.some((permission) => hasPermission(permission))
  }
  if (section.links) {
    return section.links.some(
      (link) => !link.permission || link.permission.some((permission) => hasPermission(permission)),
    )
  }
  return true
}

const onOverlaySectionClick = (sectionIdentifier: string) => {
  if (currentlyInflatedOverlayMenu.value === sectionIdentifier) {
    resetInflatedOverlayMenu()
    return
  }
  currentlyInflatedOverlayMenu.value = sectionIdentifier
}

const onSectionClick = (sectionIdentifier: string) => {
  if (currentlyInflatedCollapseMenu.value === sectionIdentifier) {
    resetInflatedCollapseMenu()
    return
  }
  currentlyInflatedCollapseMenu.value = sectionIdentifier
}

const resetInflatedCollapseMenu = () => {
  currentlyInflatedCollapseMenu.value = ''
}

const resetInflatedOverlayMenu = () => {
  currentlyInflatedOverlayMenu.value = ''
}

const onResize = () => {
  const currentWidth = window.innerWidth
  const { xl } = screens
  if (currentWidth < xl && lastWidth.value >= xl) {
    collapseNavigation()
  } else if (currentWidth >= xl && lastWidth.value < xl && !manuallyCollapsed.value) {
    expandNavigation()
  }
  lastWidth.value = currentWidth
}
const collapseIfNeeded = () => {
  if (window.innerWidth < screens.xl) {
    collapseNavigation()
  }
}
/**
 * Collapses the currently inflated MenuSection when the navigation is expanded or collapsed.
 */
watch(expanded, () => {
  resetInflatedCollapseMenu()
})
/**
 * If on mobile collapses the entire navigation, when a navigation item is selected
 */
watch(route, () => {
  collapseIfNeeded()
})

/**
 * On mount we add the resize listener
 * We also check if the navigation should be collapsed or not.
 * This is done in $nextTick because else #layout-root might be undefined
 */
onMounted(() => {
  window.addEventListener('resize', onResize)
  nextTick(() => {
    if (manuallyCollapsed.value) {
      collapseNavigation()
    } else {
      collapseIfNeeded()
    }
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', onResize)
})
</script>

<style lang="scss" scoped>
@use '@/sass/variables' as *;

.navigation {
  @media (min-width: 1024px) {
    width: $width-sidebar;
  }

  //Defines the scrollbar background if a collapse menu section is inflated and overflows screen height
  ::-webkit-scrollbar {
    background-color: transparent;
    width: 8px;
  }

  ::-webkit-scrollbar-thumb {
    @apply tw-bg-gray-white;
    border-radius: 100px;
  }

  &--expanded {
    @media (min-width: 1024px) {
      width: $width-sidebar-expanded;
    }
  }
}
</style>
