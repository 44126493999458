import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

/**
 * Takes a Dayjs object or a string and returns true if it is in the future.
 *
 * @param {Dayjs | string} date - the date/time to check if it is in the future
 */
export const isInFuture = (date: string | Dayjs) => {
  if (typeof date === 'string') {
    date = dayjs(date)
  }
  return date > dayjs()
}

export const getCurrentDate = (): string => {
  return dayjs().format('YYYY-MM-DD')
}

export const getCurrentDateTime = (): string => {
  return dayjs().format('YYYY-MM-DD HH:mm')
}

export const getCurrentFullDateTime = (): string => {
  return dayjs().format('YYYY-MM-DD HH:mm:ss')
}

export const getAllDatesBetween = (startDate: string, endDate: string): string[] => {
  const datesArray: string[] = []
  let currentDate = dayjs(startDate)
  const finalDate = dayjs(endDate)

  while (currentDate.isBefore(finalDate) || currentDate.isSame(finalDate, 'day')) {
    datesArray.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }

  return datesArray
}

export const getMonthOrYearOfDate = (param: 'month' | 'year', date: string | number) => {
  if (!date) {
    return ''
  }

  if (param === 'month') {
    return dayjs(date).month() + 1
  } else if (param === 'year') {
    return dayjs(date).year()
  }
}

export const dateTimeContainsHour = (datetime: string, hour: string) => {
  return datetime.substr(11) === hour
}
